<template>
  <div class="user-app-list-page w-100">
    <basic-subheader class="custom__basic-subheader">
      <template v-slot:filters>
        <b-row class="py-4">
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              class="btn btn-success"
              type="button"
              @click="handleExport"
              :disabled="!selectedItems.length"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/File/file-text.svg" />
              </span>
              Xuất kết quả
            </b-button>

            <div class="d-flex justify-content-end align-items-center">
              <div style="margin-left: 10px"></div>
              <b-dropdown
                id="dropdown-right dropdown-form"
                class="dropdown-form-filter mr-2"
                no-caret
                right
                ref="dropdownFormFilter"
              >
                <template #button-content>
                  <span class="svg-icon">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/filter.svg"
                    />
                  </span>
                  Bộ lọc
                </template>
                <h6 class="d-flex align-items-center mb-0 p-4">
                  <span class="svg-icon mr-3">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/filter.svg"
                    />
                  </span>
                  Bộ lọc
                </h6>
                <b-dropdown-divider> </b-dropdown-divider>
                <b-dropdown-form :class="showNewCustomer ? 'mw-600' : 'mw-400'">
                  <b-container class="p-0">
                    <b-row>
                      <b-col>
                        <basic-input
                          label="Thông tin khách hàng"
                          placeholder="Nhập tên khách hàng"
                          name="Name"
                          :value.sync="filters.Name"
                         
                        ></basic-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <basic-input
                          label="Số điện thoại"
                          placeholder="Nhập số điện thoại"
                          name="PhoneNumber"
                          :value.sync="filters.PhoneNumber"
                        
                        ></basic-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <basic-select
                          label="Khảo sát"
                          placeholder="--- Chọn khảo sát ---"
                          name="status"
                          :solid="false"
                          :allowEmpty="true"
                          value-label="text"
                          track-by="value"
                          :options="surveys"
                          :value.sync="filters.survey"
                          changeValueByObject
                          class="mb-0"
                        ></basic-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group label="Thời gian hoàn thành" class="mb-0">
                          <date-picker
                            placeholder="Từ ngày"
                            name="startDate"
                            input-class="form-control"
                            type="date"
                            :format="dateFomartion"
                            value-type="format"
                            :disabled-date="disabledBeforeFromDate"
                            v-model="filters.startDate"
                            @change="selectDate('start', $event)"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group label="Thời gian hoàn thành" class="mb-0">
                          <date-picker
                            placeholder="Đến ngày"
                            name="endDate"
                            input-class="form-control"
                            type="date"
                            :format="dateFomartion"
                            value-type="format"
                            :disabled-date="disabledBeforeToDate"
                            v-model="filters.endDate"
                            @change="selectDate('end', $event)"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <basic-select
                          label="Gói dịch vụ"
                          placeholder="--- Chọn gói dịch vụ ---"
                          name="status"
                          :solid="false"
                          :allowEmpty="true"
                          value-label="text"
                          track-by="value"
                          :options="packages"
                          changeValueByObject
                          :value.sync="filters.package"
                          class="mb-0"
                        ></basic-select>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-dropdown-form>
                <b-dropdown-divider> </b-dropdown-divider>
                <div
                  class="d-flex align-items-center justify-content-lg-end m-0 p-4"
                >
                  <b-button
                    class="btn ml-2"
                    href="#"
                    tabindex="0"
                    @click="resetRequest"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                      />
                    </span>
                    Reset bộ lọc
                  </b-button>
                  <b-button
                    class="btn ml-2"
                    href="#"
                    tabindex="0"
                    @click="searchRequest"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/filter.svg"
                      />
                    </span>
                    Lọc dữ liệu
                  </b-button>
                </div>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <!-- bộ lọc -->
      </template>
    </basic-subheader>
    <Wrapper>
      <template-table
        :column="column"
        :data="data"
        :paging="paging"
        :tableAction="false"
        :selectAction="true"
        :searchAction="false"
        @sortBy="sortRequest"
        @select="handleSelect"
      >
        <template v-slot:body="{ item }">
          <td>
            {{ item.fullNameSearch }}
          </td>
          <td>
            {{ getAge(item.dateOfBirth) }}
          </td>
          <td>
            {{ item.phoneNumber ? item.phoneNumber.replace('+84', '0') : null }}
          </td>
          <td>
            {{ item.packageName }}
          </td>
          <td>
            {{ item.surveyName }}
          </td>
          <td>
            {{ convertTimestampToDate(item.dateDone) }}
          </td>
        </template>
      </template-table>
    </Wrapper>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { CHANGE_STATUS } from '@/core/services/store/survey.module';
const { mapActions } = createNamespacedHelpers('survey');
import DatePicker from 'vue2-datepicker';
export default {
  name: 'SurveyResultList',
  components: {
    'date-picker': DatePicker,
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      column: [
        {
          key: 'name',
          label: 'Khách hàng',
          sortable: true,
          style: {
            width: '15%',
          },
        },
        {
          key: 'dateOfBirth',
          label: 'Tuổi',
          sortable: true,
          style: {
            width: '10%',
          },
        },
        {
          key: 'phoneNumber',
          label: 'SĐT',
          sortable: false,
          style: {
            width: '150px',
          },
        },
        {
          key: 'package',
          label: 'Gói dịch vụ',
          sortable: false,
          style: {
            width: '200px',
          },
        },
        {
          key: 'survey',
          label: 'Khảo sát',
          sortable: false,
        },
        {
          key: 'dateDone',
          label: 'Ngày hoàn thành',
          sortable: true,
          style: {
            width: '170px',
          },
        },
      ],
      data: [],

      sort: {
        by: null,
        order: null,
      },
      filters: {
        startDate: null,
        endDate: null,
        status: null,
        package: null,
        survey: null,
        Name: null,
        PhoneNumber: null,
      },
      dateFomartion: 'DD/MM/YYYY',
      packages: [],
      surveys: [],
      selectedItems: [],
      filteredData: [],
    };
  },

  computed: {
    searchParams() {
      return {
        Name: this.filters.Name,
        PhoneNumber: this.filters.PhoneNumber,
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        surveyId: this.filters.survey?.value || null,
        packageId: this.filters.package?.value || null,
        dateDoneTo: this.filters.endDate
          ? this.$moment(this.filters.endDate, 'DD/MM/YYYY')
              .startOf('date')
              .format('X')
          : null,
        dateDoneFrom: this.filters.startDate
          ? this.$moment(this.filters.startDate, 'DD/MM/YYYY')
              .startOf('date')
              .format('X')
          : null,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
    'filters.survey'() {
      this.resetPaging();
    },
    'filters.package'() {
      this.resetPaging();
    },
    'filters.Name'() {
      this.resetPaging();
    },
    'filters.PhoneNumber'() {
      this.resetPaging();
    },
  },
  created() {
    this.loadData();
    this.getPrepareData();
  },
  methods: {
    ...mapActions({ CHANGE_STATUS }),
    async getPrepareData() {
      try {
        const { meta, data, error } = await this.$api.get(
          '/Survey/SurveyExtend',
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.packages =
          data?.package.map((el) => ({ text: el.name, value: el.id })) || [];
        this.surveys =
          data?.survey.map((el) => ({ text: el.name, value: el.id })) || [];
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    resetRequest() {
      this.filters = {
        startDate: null,
        endDate: null,
        status: null,
        package: null,
        survey: null,
        Name: null,
        PhoneNumber: null,
      };
    },

    async loadData() {
      this.$store.commit('context/setLoading', true);
      try {
        const { meta, data, error } = await this.$api.get(
          '/Survey/SurveyTargetStastic',
          {
            params: this.searchParams,
          },
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.data = data;
        this.paging.total = meta.total;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.loadData();
    },
    resetPaging() {
      this.paging.page = 1;
      this.loadData();
    },
    disabledBeforeToDate(date) {
      if (this.filters.startDate) {
        const newDate = this.convertToISODate(this.filters.startDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date < deadline;
      }
    },
    disabledBeforeFromDate(date) {
      if (this.filters.endDate) {
        const newDate = this.convertToISODate(this.filters.endDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date > deadline;
      }
    },
    selectDate(type, date) {
      if (date) {
        if (type === 'start') {
          this.filters.startDate = date;
        } else {
          this.filters.endDate = date;
        }
      } else {
        if (type === 'start') {
          this.filters.startDate = null;
        } else {
          this.filters.endDate = null;
        }
      }
      this.loadData();
    },
    getAge(timestamp) {
      const currentYear = new Date().getFullYear();
      const yearOfBirth = Number(
        this.convertTimestampToDate(timestamp, 'YYYY'),
      );
      return currentYear - yearOfBirth;
    },
    handleSelect(selectedItems) {
      this.selectedItems = selectedItems;
    },
    getFileName(string) {
      if (!string) return;

      return string.split('; ')[1].split('filename=')[1];
    },
    async handleExport() {
      this.$store.commit('context/setLoading', true);
      const params = {
        downloadFilters: this.selectedItems.map((el) => ({
          patientId: el.patientId,
          accountId: el.accountId,
          surveyId: el.surveyId,
        })),
      };

      this.$api
        .post('/UserDashBoard/Survey-Download-Multiple', params, {
          responseType: 'blob',
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${this.getFileName(res.headers['content-disposition'])}`,
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: 'Xuất kết quả lỗi',
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom__basic-subheader {
  display: block;
  justify-content: unset;
  align-items: unset;
}
.tab-active {
  background-color: #fff !important;
  border: 1px solid #01645a;
}
</style>

<style lang="scss" scoped>
.wrap-icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-content: center;
  background: #e4f5f5;
  border-radius: 8px;
}

.wrap__tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 25px;

  .tab {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;

    &.tab__active {
      border: 1.5px solid #fdb913;
    }
  }
}

.wrap-icon-calender {
  background: #e6f6ed;
}
.wrap-icon-card {
  background: #fff7c0;
}
.wrap-icon-group {
  background: #feeddc;
}

.wrap-title {
  h5 {
    text-align: left;
  }

  div {
    color: #000;
    text-align: left;
  }
}
.mw-400 {
  min-width: 400px;
}
.mw-600 {
  min-width: 600px;
}
</style>
